<template>
  <div>
    <div v-if="loading !== 'error'">
      <back-navigation
        title="Edit Project"
        prevent-back
        @preventBack="$bvModal.show('preventBack')"
      />
      <b-modal
        id="preventBack"
        title="Ingin keluar?"
        centered
      >
        Yakin ingin keluar? Progress pengisian form anda belum disimpan.
        <template
          #modal-footer
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            @click="$bvModal.hide('preventBack'); $router.go(-1)"
          >
            Ya, keluar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="$bvModal.hide('preventBack')"
          >
            Tetap Disini
          </b-button>
        </template>
      </b-modal>
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <validation-observer ref="validateProject">
          <b-form @submit.prevent>
            <b-row>
              <b-col
                cols="12"
                xl="8"
              >
                <XyzTransition
                  appear
                  xyz="fade left-3 delay-3"
                >
                  <div>
                    <b-card>
                      <b-row>
                        <b-col cols="12">
                          <b-form-group
                            label="Customer"
                            label-cols-md="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Customer"
                              rules="required"
                            >
                              <customer-auto-suggest
                                v-model="projectData.customer"
                                @onSelected="handleSelected"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group
                            label="Nama Project"
                            label-cols-md="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Nama project"
                              rules="required|min:10|max:45"
                            >
                              <b-form-textarea
                                v-model="projectData.projectName"
                                placeholder="Nama project"
                                rows="3"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <b-form-group
                            label="Tanggal Reminder"
                            label-cols-md="3"
                          >
                            <b-form-datepicker
                              v-model="projectData.reminderDate"
                              today-button
                              reset-button
                              menu-class="w-100"
                              calendar-width="100%"
                              locale="id-ID"
                              placeholder="Pilih tanggal"
                              @context="onContext"
                            />
                          </b-form-group>
                          <b-form-group
                            label="Kategori"
                            label-cols-md="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Kategori"
                              rules="required"
                            >
                              <v-select
                                v-model="projectData.category"
                                label="name"
                                :options="projectCategory"
                                placeholder="Kategori"
                                :clearable="false"
                                @option:selected="getAreaOptions()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                    <b-collapse :visible="selectedArea.length > 0">
                      <b-card title="Luas Area">
                        <b-row>
                          <b-col cols="12">
                            <b-form-group
                              v-for="(area, index) in selectedArea"
                              :key="index"
                              :label="area.name"
                              label-cols-md="3"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Luas Area"
                                rules="integer|min_value:0|max_value:99999|positive|required"
                                class="w-100"
                              >
                                <b-input-group
                                  append="M2"
                                  class="input-group-merge"
                                >
                                  <b-form-input
                                    v-model="selectedArea[index].luasArea"
                                    type="number"
                                    min="1"
                                    max="99999"
                                    placeholder="Luas Area"
                                  />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-collapse>
                  </div>
                </XyzTransition>
              </b-col>
              <b-col
                cols="12"
                xl="4"
              >
                <XyzTransition
                  appear
                  xyz="fade right-3 delay-2"
                >
                  <div
                    v-if="projectData.customer && projectData.projectName && projectData.category"
                    class="box"
                  >
                    <div class="sidebar">
                      <b-card>
                        <h4 class="mb-1">
                          Summary
                        </h4>
                        <div class="d-flex align-items-center">
                          <b-avatar
                            class="mr-75"
                            :size="40"
                            :text="avatarText(projectData.customer.name)"
                          />
                          <div>
                            <h6 class="m-0">
                              {{ projectData.customer.name }}
                            </h6>
                            <small class="text-muted d-block">
                              {{ projectData.customer.customerNo }}
                            </small>
                            <small class="text-muted">{{ projectData.customer.email }}</small>
                          </div>
                        </div>
                        <hr>
                        <h6>{{ projectData.projectName }}</h6>
                        <b-badge
                          v-if="projectData.category"
                          variant="success"
                        >
                          {{ projectData.category.name }}
                        </b-badge>
                        <div class="d-flex align-items-center mt-1">
                          <b-avatar
                            class="mr-50"
                            variant="light-success"
                            rounded
                          >
                            <feather-icon
                              icon="CalendarIcon"
                              size="20"
                            />
                          </b-avatar>
                          <h6 class="m-0">
                            {{ formattedDate }}
                          </h6>
                        </div>
                        <hr>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          block
                          variant="primary"
                          @click="loadingEdit ? null : submitForm()"
                        >
                          <span v-if="!loadingEdit">Update</span>
                          <b-spinner
                            v-else
                            small
                          />
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-secondary"
                          block
                          class="mt-1"
                          @click="fetchDetail"
                        >
                          Reset
                        </b-button>
                      </b-card>
                    </div>
                  </div>
                </XyzTransition>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </div>
    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />
  </div>
</template>

<script>
import BackNavigation from '@/components/misc/BackNavigation.vue'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import {
  BCard, BForm, BFormGroup, BFormTextarea, BFormDatepicker, BRow, BCol, BButton,
  BSpinner, BAvatar, BBadge, BCollapse, BFormInput, BInputGroup, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
import CustomerAutoSuggest from '@/components/input/CustomerAutoSuggest.vue'

export default {
  components: {
    BackNavigation,
    ErrorEndpoint,
    BCard,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BAvatar,
    BBadge,
    BCollapse,
    BFormInput,
    BInputGroup,
    BModal,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    CustomerAutoSuggest,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      avatarText,
      loading: true,
      loadingEdit: false,
      projectData: {
        customer: '',
        projectName: '',
        reminderDate: '',
        category: '',
      },
      formattedDate: '',
      required,
      min,
      max,
      error: '',
      loadingAreaOptions: false,
      areaOptions: [],
      selectedArea: [],
      prevItems: '',
      prevSelectedArea: '',
    }
  },
  computed: {
    ...mapGetters({
      projectCategory: 'getProjectCategory',
    }),
  },
  mounted() {
    this.fetchDetail()

    if (this.projectCategory.length < 1) {
      this.$store.dispatch('getProjectCategory')
    }
  },
  methods: {
    ...mapActions(['getProjectDetail']),
    fetchDetail() {
      this.areaOptions = []
      this.selectedArea = []
      this.getProjectDetail(Number(this.id)).then(result => {
        if (result.projectDetail.isAssigned || this.$root.role === 2) {
          this.projectData = {
            customer: result.projectDetail.customer,
            projectName: result.projectDetail.name,
            reminderDate: result.projectDetail.tgl_reminder,
            category: {
              id: result.projectDetail.category.id,
              name: result.projectDetail.category.name,
            },
          }

          if (result.projectDetail.surfaceAreas.length) {
            result.projectDetail.surfaceAreas.forEach(el => {
              if (el.area_category.name && el.area_category.name.toLowerCase() !== 'free item' && el.area_category.name.toLowerCase() !== 'anchor') {
                this.selectedArea.push({
                  id: el.area_category.id,
                  name: el.area_category.name,
                  luasArea: el.surface_area,
                })
              }
            })
          } else {
            this.getAreaOptions()
          }
          this.prevItems = JSON.stringify(this.projectData)
          this.prevSelectedArea = JSON.stringify(this.selectedArea)
          this.loading = false
        } else {
          this.$router.push('/unauthorized')
        }
      }).catch(error => {
        this.loading = 'error'
        this.error = `${error.graphQLErrors[0].message}`
      })
    },
    onContext(ctx) {
      this.formattedDate = ctx.selectedFormatted
    },
    handleSelected(data) {
      this.projectData.customer = data
    },
    getAreaOptions() {
      this.selectedArea = []
      this.loadingAreaOptions = true
      this.$store.dispatch('getAreaCategory', this.projectData.category.id).then(category => {
        this.areaOptions = category.areaCategory
        this.loadingAreaOptions = false
        this.areaOptions.forEach(el => {
          if (el.name && el.name.toLowerCase() !== 'free item' && el.name.toLowerCase() !== 'anchor') {
            this.selectedArea.push({
              id: el.id,
              name: el.name,
              luasArea: 0,
            })
          }
        })
      }).catch(() => {
        this.loadingAreaOptions = false
      })
    },
    submitForm() {
      const isInvalidArea = this.selectedArea.filter(el => !Number.isInteger(parseFloat(el.luasArea)))
      if (!isInvalidArea.length) {
        this.$refs.validateProject.validate().then(success => {
          if (success) {
            this.loadingEdit = true
            this.$store.dispatch('updateProject', {
              id: +this.id,
              customerId: this.projectData.customer.id,
              projectName: this.projectData.projectName,
              category: this.projectData.category.id,
              reminderDate: new Date(this.projectData.reminderDate),
              surfaceArea: this.selectedArea.length ? this.selectedArea.map(el => ({
                area_category_id: el.id,
                surface_area: el.luasArea ? +el.luasArea : 0,
              })) : null,
            }).then(() => {
              this.loadingEdit = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Project berhasil diubah!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.replace({ name: 'project-detail', params: { id: this.id.toString() } })
            }).catch(() => {
              this.loadingEdit = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Gagal mengubah!',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
          } else {
            if (!this.projectData.customer) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Pilih customer terlebih dahulu!',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            }

            if (!this.projectData.category) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Pilih kategori terlebih dahulu!',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            }
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Luas area ${isInvalidArea[0].name} tidak valid`,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    resetForm() {
      this.$swal({
        title: 'Reset form?',
        text: 'Progress pengisian form anda akan direset dan tidak dapat dikembalikan.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.projectData = JSON.parse(this.prevItems)
          this.selectedArea = JSON.parse(this.prevSelectedArea)

          // Object.keys(this.projectData).forEach(key => {
          //   this.projectData[key] = ''
          // })
          // this.$refs.validateProject.reset()
        }
      })
    },
  },
}
</script>

<style>
  @media (min-width: 1200px) {
    .box {
      position: fixed;
      width: 20%;
      transition: opacity 500ms linear;
    }

    .sidebar {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
